<template>
  <b-row>
    <b-col sm="10" md="7" lg="7" class="">
      <div class="card px-lg-4 px-md-3 px-2 py-3">
        <div>
          <h6 class="">Name of the Client</h6>
          <h5 class="bold">{{ clientData.name || "No" }}</h5>
        </div>
        <div class="">
          <div class="mt-2">
            <h6 class="">About the Client</h6>
            <h5 class="bold">
              {{ clientData.about || "No" }}
            </h5>
          </div>
          <div class="mt-2">
            <h6 class="">Company Employee Strength</h6>
            <h5 class="bold">{{ clientData.strength || "No" }}</h5>
          </div>
          <div class="mt-3">
            <h4 class="bold">Location Details</h4>
            <div class="mt-2">
              <h6 class="mt-1">Address line 1</h6>
              <h5 class="bold">
                {{ clientData.address1 || "No" }}
              </h5>
            </div>
            <div class="mt-2">
              <h6 class="mt-1">Address line 2</h6>
              <h5 class="bold">
                {{ clientData.address2 || "No" }}
              </h5>
            </div>
            <b-row class="mt-1">
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">City</h6>
                <h5 class="bold">{{ clientData.city || "No" }}</h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">State</h6>
                <h5 class="bold">{{ clientData.state || "No" }}</h5>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">ZIPCODE</h6>
                <h5 class="bold">{{ clientData.zipcode || "No" }}</h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Country</h6>
                <h5 class="bold">{{ clientData.country || "No" }}</h5>
              </b-col>
            </b-row>
            <div class="mt-3 ml-0 mr-0 mb-2">
              <h4 class="mb-1 bold">Payment Structure</h4>
              <b-col class="border rounded-lg px-2 py-1">
                <div>
                  <h6 class="mt-1">Hourly Pricing (Phone)</h6>
                  <h5 class="bold">
                    {{ clientData.hourly_price_phone || "No" }}
                  </h5>
                </div>
                <div>
                  <h6 class="mt-1">Hourly Pricing (Face to Face)</h6>
                  <h5 class="bold">
                    {{ clientData.hourly_price_face || "No" }}
                  </h5>
                </div>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </b-col>

    <b-col md="5" lg="5" class="pr-2 mt-0">
      <div class="card px-lg-3 px-2 pt-2 pb-3">
        <h4 class="bold mt-2">Location Details</h4>
        <div>
          <h6 class="mt-1">Email ID</h6>
          <h5 class="bold">{{ clientData.email || "No" }}</h5>
        </div>
        <div>
          <h6 class="mt-1">Phone Number</h6>
          <h5 class="bold">{{ clientData.phone || "No" }}</h5>
        </div>
        <div>
          <h6 class="mt-1">Website</h6>
          <h5 class="bold text-secondary">{{ clientData.website || "No" }}</h5>
        </div>
        <div class="mt-3">
          <h4 class="bold">Social Presence</h4>
          <div class="mt-2 mb-1">
            <h5 class="">Linkedin</h5>
            <h6 class="bold">{{ clientData.linkdin || "No" }}</h6>
          </div>
          <div class="my-1">
            <h5 class="">Facebook</h5>
            <h6 class="bold">{{ clientData.facebook || "No" }}</h6>
          </div>
          <div class="my-1">
            <h5 class="">Instagram</h5>
            <h6 class="bold">{{ clientData.instagram || "No" }}</h6>
          </div>
          <div class="my-1">
            <h5 class="">Skype</h5>
            <h6 class="bold">{{ clientData.skype || "No" }}</h6>
          </div>
        </div>
      </div>
    </b-col>
    <b-col
      cols="12"
      class=""
      v-if="
        clientData &&
        clientData.client_users &&
        clientData.client_users.length !== 0
      "
    >
      <div class="card px-lg-3 px-2 pt-2 py-2">
        <h4 class="pt-1 bold">User Details</h4>
        <b-row>
          <b-col cols="12" class="my-1">
            <div v-for="(user, i) in clientData.client_users" :key="i">
              <h5 class="bold">
                {{ i + 1 }}. {{ user.email }}

                <b-badge
                  v-if="user.role_id === 6"
                  variant="light-primary"
                  size="sm"
                >
                  Super User
                </b-badge>
              </h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col
      cols="12"
      class=""
      v-if="clientData.assignment && clientData.assignment.length !== 0"
    >
      <div class="card px-lg-3 px-2 pt-2 py-2">
        <h4 class="pt-1 bold">Assignment Details</h4>
        <b-row>
          <b-col cols="12" class="my-1">
            <div v-for="(data, i) in clientData.assignment" :key="i">
              <h5 class="bold">
                {{ i + 1 }}.
                <b-link class="alert-link" @click="openAssignment(data)">
                  {{ data.title }}
                </b-link>
              </h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12" class="" v-if="clientData.notes">
      <div class="card px-lg-3 px-2 pt-2 py-2">
        <h4 class="pt-1 bold">Notes about the Client</h4>
        <b-row>
          <b-col cols="12" class="my-1">
            <div v-for="(note, i) in JSON.parse(clientData.notes)" :key="i">
              <h5 class="bold">{{ i + 1 }}. {{ note.note }}</h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "@/libs/axios"
import errorResponse from "@/libs/axiosError"
// eslint-disable-next-line object-curly-newline
import { BCol, BRow, BBadge, BLink } from "bootstrap-vue"

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BLink,
  },
  data() {
    return {
      clientData: {},
    }
  },
  mounted() {
    this.getClients()
  },
  methods: {
    async getClients() {
      axios
        .get(`/get-client-by-id/${this.$route.params.id}`)
        .then((res) => {
          this.clientData = res.data
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    openAssignment(data) {
      this.$router.push({
        path: `/details-admin-engagement/${data.id}`,
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}
.bold {
  font-weight: 700;
}
.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.file_bg {
  background-color: #dcdcdc;
}
</style>
